import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter , Route , Switch , Redirect } from 'react-router-dom';
import PrivacySection from './components/Privacy/PrivacySection';


ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/home" render={(props => <App/>)}/>
            <Route path="/privacy_suberesmitra" render={(props => <PrivacySection/>)}/>
            <Redirect from="/" to="/home"/>
        </Switch>
    </BrowserRouter>     
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
