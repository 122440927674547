import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import HeroSection from './components/HeroSection/HeroSection';
import Features from './components/Features/Features';
import PricingSection from './components/Pricing/PricingSection';
import TeamSection from './components/TeamSection/TeamSection'
import PrivacySection from './components/Privacy/PrivacySection';

function App() {
  return (
    <div>
      <Header/>
      <HeroSection/>
      <main id="main">
        <About/>
        <Features/>
        <PricingSection/>
        {/* <TeamSection/> */}
      </main>
      <Footer/>
    </div>
  );
}

export default App;
