import React, { Component, useEffect, useState } from 'react';

const About = () => {
    const [mobile, setMobile] = useState(false)
    useEffect(() => {
        if (/Android|iPhone/i.test(navigator.userAgent)) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    })
    return (
        <div>
            {
                !mobile ?
                    <section id="layanan" className="about">
                        <div className="container">
                            <div className="section-title" data-aos="fade-up">
                                <p style={{ fontSize: '25px', textTransform: 'none' }}>Layanan Kami</p>
                            </div>
                            <div className="row content">
                                {/* <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                        <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                    </div> */}
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/GeneralCleaning.jpg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                                    <h3>General Cleaning</h3>
                                    <p>Layanan yang dilakukan pada waktu-waktu tertentu saja.
                                        Layanan ini sangat cocok dipakai untuk membersihkan rumah setelah pindahan dengan menerapkan sistem detail cleaning Untuk rumah
                                        pribadi / ruangan kantor yang akan digunakan.
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                            <div className="row content">
                                <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/HomeCleaning.jpg" class="img-fluid" alt="" />
                                </div>
                                <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Home Cleaning</h3>
                                    <p>
                                        Layanan kebersihan yang paling menjadi favorit. Yang mana pembersihan dilakukan secara menyeluruh. Meliputi
                                        kegiatan kebersihan rumah sehari-sehari atau suatu ruangan sesuai dengan jenis kebutuhan. Tidak hanya rumah namun bisa juga perkantoran atau pertokoan
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginTop: '20px' }} className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px', width: '330px', height: '300px', objectFit: 'cover' }} src="assets/img/FoggingCleaning.jpeg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Fogging</h3>
                                    <p>Layanan kebersihan khusus untuk menghambat atau membunuh bakteri, virus dan jamur. Yang menggunak cairan pembersihan yang sudah teruji kandungan nya dan aman untuk manusia .
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                            <div className="row content">
                                <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
                                    <img style={{ borderRadius: '10px', width: '330px', height: '300px', objectFit: 'cover' }} src="assets/img/DeepCleaning.png" class="img-fluid" alt="" />
                                </div>
                                <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Deep Cleaning</h3>
                                    <p>
                                        Deep Cleaning adalah melakukan pembersihan rumah secara lengkap pada seluruh bagian rumah. Deep cleaning hanya dilakukan oleh petugas Jasa Cleaning Service dengan peralatan khusus yang dapat melakukannya.
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginTop: '15px' }} className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/VacuumTungau.jpg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Vacuum Tungau</h3>
                                    <p>Jasa sedot atau vacuum tungau dan debu dengan menggunakan HydroAllergenic®️ System berteknologi separator, yang memiliki daya hisap lebih dari 20.000 rpm dan mampu memisahkan udara, partikel debu dan tungau.
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section id="layanan" className="about">
                        <div className="container">
                            <div className="section-title" data-aos="fade-up">
                                <p style={{ fontSize: '25px', textTransform: 'none' }}>Layanan Kami</p>
                            </div>
                            <div className="row content">
                                {/* <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                        <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                    </div> */}
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/GeneralCleaning.jpg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                                    <h3>General Cleaning</h3>
                                    <p>Layanan yang dilakukan pada waktu-waktu tertentu saja.
                                        Layanan ini sangat cocok dipakai untuk membersihkan rumah setelah pindahan dengan menerapkan sistem detail cleaning Untuk rumah
                                        pribadi / ruangan kantor yang akan digunakan.
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                            <div className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/HomeCleaning.jpg" class="img-fluid" alt="" />
                                </div>
                                <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Home Cleaning</h3>
                                    <p>
                                        Layanan kebersihan yang paling menjadi favorit. Yang mana pembersihan dilakukan secara menyeluruh. Meliputi
                                        kegiatan kebersihan rumah sehari-sehari atau suatu ruangan sesuai dengan jenis kebutuhan. Tidak hanya rumah namun bisa juga perkantoran atau pertokoan
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginTop: '20px' }} className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px', width: '360px', height: '300px', objectFit: 'cover' }} src="assets/img/FoggingCleaning.jpeg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Fogging</h3>
                                    <p>Layanan kebersihan khusus untuk menghambat atau membunuh bakteri, virus dan jamur. Yang menggunak cairan pembersihan yang sudah teruji kandungan nya dan aman untuk manusia .
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                            <div className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px', width: '360px', height: '300px', objectFit: 'cover' }} src="assets/img/DeepCleaning.png" class="img-fluid" alt="" />
                                </div>
                                <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Deep Cleaning</h3>
                                    <p>
                                        Deep Cleaning adalah melakukan pembersihan rumah secara lengkap pada seluruh bagian rumah. Deep cleaning hanya dilakukan oleh petugas Jasa Cleaning Service dengan peralatan khusus yang dapat melakukannya.
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginTop: '15px' }} className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/VacuumTungau.jpg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Vacuum Tungau</h3>
                                    <p>Jasa sedot atau vacuum tungau dan debu dengan menggunakan HydroAllergenic®️ System berteknologi separator, yang memiliki daya hisap lebih dari 20.000 rpm dan mampu memisahkan udara, partikel debu dan tungau.
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </div>
    )
}

export default About