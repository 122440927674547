import React , { Component } from 'react';

class TeamSection extends Component {
    render(){
        return (
            <section id="team" className="team">
                <div className="container">
                    <div className="section-title" data-aos="fade-up">
                        <p style={{fontSize : '25px' , textTransform : 'none'}}>Tim Kami</p>
                    </div>
                    <div className="row" data-aos="fade-left">
                        <div className="col-lg-3 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pic"><img src="assets/img/team/foto_bunda.jpeg" className="img-fluid" alt=""/></div>
                                <div className="member-info">
                                    <h4>Yusniar Munir</h4>
                                    <span>Chief Executive Officer</span>
                                    <div className="social">
                                        <a href=""><i className="icofont-twitter"></i></a>
                                        <a href=""><i className="icofont-facebook"></i></a>
                                        <a href=""><i className="icofont-instagram"></i></a>
                                        <a href="#"><i className="icofont-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pic"><img src="assets/img/team/foto_daffa.jpg" className="img-fluid" alt=""/></div>
                                <div className="member-info">
                                    <h4>Daffa Naufal Rachmat</h4>
                                    <span>Chief Technology Officer</span>
                                    <div className="social">
                                        <a href=""><i className="icofont-twitter"></i></a>
                                        <a href=""><i className="icofont-facebook"></i></a>
                                        <a href=""><i className="icofont-instagram"></i></a>
                                        <a href="#"><i className="icofont-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pic"><img src="assets/img/team/foto_dimas.jpg" className="img-fluid" alt=""/></div>
                                <div className="member-info">
                                    <h4>Dimas</h4>
                                    <span>Chief Financial Officer</span>
                                    <div className="social">
                                        <a href=""><i className="icofont-twitter"></i></a>
                                        <a href=""><i className="icofont-facebook"></i></a>
                                        <a href=""><i className="icofont-instagram"></i></a>
                                        <a href="#"><i className="icofont-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pic"><img src="assets/img/team/foto_deasty.jpeg" className="img-fluid" alt=""/></div>
                                <div className="member-info">
                                    <h4>Deasty</h4>
                                    <span>Chief Operational Officer</span>
                                    <div className="social">
                                        <a href=""><i className="icofont-twitter"></i></a>
                                        <a href=""><i className="icofont-facebook"></i></a>
                                        <a href=""><i className="icofont-instagram"></i></a>
                                        <a href="#"><i className="icofont-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row" data-aos="fade-left">
                    <div className="col-lg-3 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pic"><img src="assets/img/team/foto_anggress.jpeg" className="img-fluid" alt=""/></div>
                                <div className="member-info">
                                    <h4>Anggress</h4>
                                    <span>Chief Marketing Officer</span>
                                    <div className="social">
                                        <a href=""><i className="icofont-twitter"></i></a>
                                        <a href=""><i className="icofont-facebook"></i></a>
                                        <a href=""><i className="icofont-instagram"></i></a>
                                        <a href="#"><i className="icofont-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default TeamSection