import React , { Component } from 'react';

export default class Header extends Component {
    render(){
        return (
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <div className="container d-flex align-items-center">
                    <div className="logo mr-auto">
                        <h1 className="text-alight"><a href="index.html"><span>Suberes</span></a></h1>
                    </div>
                    <nav className="nav-menu d-none d-lg-block">
                        <ul>
                            <li class="active"><a href="index.html">Beranda</a></li>
                            <li><a href="#layanan">Layanan</a></li>
                            <li><a href="#features">Keunggulan</a></li>
                            <li><a href="#pricing">Harga</a></li>
                            {/* <li class="drop-down"><a href="">Drop Down</a>
                                <ul>
                                <li><a href="#">Drop Down 1</a></li>
                                <li class="drop-down"><a href="#">Drop Down 2</a>
                                    <ul>
                                    <li><a href="#">Deep Drop Down 1</a></li>
                                    <li><a href="#">Deep Drop Down 2</a></li>
                                    <li><a href="#">Deep Drop Down 3</a></li>
                                    <li><a href="#">Deep Drop Down 4</a></li>
                                    <li><a href="#">Deep Drop Down 5</a></li>
                                    </ul>
                                </li>
                                <li><a href="#">Drop Down 3</a></li>
                                <li><a href="#">Drop Down 4</a></li>
                                <li><a href="#">Drop Down 5</a></li>
                                </ul>
                            </li> */}
                        </ul>
                    </nav>
                </div>
            </header>
        )
    }
}