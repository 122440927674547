import React , { Component } from 'react';

export default class PrivacyHeader extends Component {
    render(){
        return (
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <div className="container d-flex align-items-center">
                    <div className="logo mr-auto">
                        <h1 className="text-alight"><a href="index.html"><span>Suberes Partner Privacy Policy</span></a></h1>
                    </div>
                </div>
            </header>
        )
    }
}