import React , { Component } from 'react';

class Features extends Component {
    render(){
        return (
            <section id="features" className="counts">
                <div className="container">
                    <div className="section-title" style={{textTransform : 'capitalize' }} data-aos="fade-up">
                        <p style={{fontSize : '25px' , textTransform : 'none'}}>Keunggulan kami</p>
                    </div>
                    <div className="row" style={{marginTop : '40px' }} data-aos="fade-up">
                        <div className="col-lg-3 col-md-6">
                            <div className="count-box">
                                <i className="icofont-check" style={{backgroundColor : 'white' , border : '1px solid #34B762' , color : '#34B762'}}></i>
                                <p style={{ fontWeight : 'bold' }}>Pelayanan</p>
                                <p style={{marginTop : '20px' , fontSize : '12px'}}>
                                    Pelayanan yang dapat di sesuaikan dengan kebutuhan pelanggan
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-check" style={{backgroundColor : 'white' , border : '1px solid #34B762' , color : '#34B762'}}></i>
                                <p style={{ fontWeight : 'bold'}}>Mitra / Operasional</p>
                                <p style={{marginTop : '20px' , fontSize : '12px'}}>
                                    Layanan kami akan dikerjakan oleh Mitra / Operasional yang berpengalaman di bidadng nya
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-check" style={{backgroundColor : 'white' , border : '1px solid #34b762' , color : '#34b762'}}></i>
                                <p style={{ fontWeight : 'bold'}}>Sistem Kerja</p>
                                <p style={{fontSize : '12px' , marginTop : '20px' }}>
                                    Memiliki sistem kerja Standar Operasional Prosedur , Daily Activity dan Checklist Order
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-check" style={{backgroundColor : 'white' , border : '1px solid #34b762' , color : '#34b762'}}></i>
                                <p style={{ fontWeight : 'bold'}}>Penggunaan Alat</p>
                                <p style={{marginTop : '20px' , fontSize : '12px'}}>
                                    Alat yang digunakan sudah sesuai standar nasional yang ditetapkan
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop : '40px'}} data-aos="fade-up">
                        <div className="col-lg col-md-6 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-check" style={{backgroundColor : 'white' , border : '1px solid #34b762' , color : '#34b762'}}></i>
                                <p style={{ fontWeight : 'bold' }}>Penggunaan Bahan</p>
                                <p style={{fontSize : '13px' , marginTop : '20px'}}>
                                    Menggunakan chemical / bahan kimia yang ramah lingkungan serta aman untuk Manusia dan Hewan.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Features