import React, { Component, useReducer } from 'react';
import PrivacyHeader from './PrivacyHeader';
import PrivacyAbout from './PrivacyAbout';
import PrivacyHero from './PrivacyHero';

const PrivacySection = () => {
    return (
        <>
            <PrivacyHeader/>
            <PrivacyHero/>
            <main id="main">
                <PrivacyAbout/>
            </main>
        </>
    )
}

export default PrivacySection