import React, { Component, useReducer } from 'react';

const PricingSection = () => {
    const [buttonConfig, setButtonConfig] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            apartment: false,
            house: false,
            mall: false,
            vehicle: false,
            page: false
        }
    )
    const defaultUnhoverButton = {
        marginTop: '10px',
        border: '1px green solid',
        cursor: 'pointer',
        background: 'white',
        color: '#34B762'
    }
    const defaultHoverButton = {
        marginTop: '10px',
        border: 'none',
        cursor: 'pointer',
        background: '#34B762',
        color: 'white'
    }
    return (
        <div>
            <section id="pricing" className="counts">
                <div className="container">
                    <div className="section-title" style={{ textTransform: 'capitalize' }} data-aos="fade-up">
                        <p style={{ fontSize: '25px', textTransform: 'none' }}>Harga Layanan</p>
                    </div>
                    <div className="row" style={{ marginTop: '40px' }} data-aos="fade-up">
                        <div className="col-lg-3 col-md-6">
                            <div style={{ display: 'block' }} className="count-box">
                                <i className="icofont-ui-home" style={{ backgroundColor: '#34B762', border: '1px solid #34B762', color: 'white' }}></i>
                                <p style={{ fontWeight: 'bold' }}>Suberes <br />Home Cleaning</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>1.5 Jam</p><p style={{ fontSize: '11px' }}>Rp 90.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>2 Jam</p><p style={{ fontSize: '11px' }}>Rp 115.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>3 Jam</p><p style={{ fontSize: '11px' }}>Rp 165.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>4 Jam</p><p style={{ fontSize: '11px' }}>Rp 215.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>5 Jam</p><p style={{ fontSize: '11px' }}>Rp 265.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>6 Jam</p><p style={{ fontSize: '11px' }}>Rp 305.000</p>
                                <a href="https://api.whatsapp.com/send?phone=6281196110699&text=Halo, saya ingin pesan paket Suberes Home Cleaning" target="_blank" style={
                                    buttonConfig.apartment ?
                                        defaultHoverButton : defaultUnhoverButton
                                } onMouseOver={() => {
                                    setButtonConfig({ ['apartment']: true })
                                }} onMouseOut={() => {
                                    setButtonConfig({ ['apartment']: false })
                                }} className="btn btn-success">Pesan Sekarang</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-bed" style={{ backgroundColor: '#34B762', border: '1px solid #34B762', color: 'white' }}></i>
                                <p style={{ fontWeight: 'bold' }}>Suberes Cuci Kasur</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kasur (90 x 200cm)</p><p style={{ fontSize: '11px' }}>Rp 145.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kasur (120 x 200cm)</p><p style={{ fontSize: '11px' }}>Rp 190.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kasur (160 x 200cm)</p><p style={{ fontSize: '11px' }}>Rp 215.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kasur (180 x 200cm)</p><p style={{ fontSize: '11px' }}>Rp 240.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kasur (200 x 200cm)</p><p style={{ fontSize: '11px' }}>Rp 260.000</p>
                                <a href="https://api.whatsapp.com/send?phone=6281196110699&text=Halo, saya ingin pesan paket Suberes Cuci Kasur" target="_blank" style={
                                    buttonConfig.house ?
                                        defaultHoverButton : defaultUnhoverButton
                                } onMouseOver={() => {
                                    setButtonConfig({ ['house']: true })
                                }} onMouseOut={() => {
                                    setButtonConfig({ ['house']: false })
                                }} className="btn btn-success">Pesan Sekarang</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-bed" style={{ backgroundColor: '#34B762', border: '1px solid #34B762', color: 'white' }}></i>
                                <p style={{ fontWeight: 'bold' }}>Suberes Cuci Sofa</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Sofa S (1 Seat)</p><p style={{ fontSize: '11px' }}>Rp 98.500</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Sofa M (2 Seat)</p><p style={{ fontSize: '11px' }}>Rp 162.500</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Sofa Bed </p><p style={{ fontSize: '11px' }}>Rp 202.500</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Sofa L</p><p style={{ fontSize: '11px' }}>Rp 230.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Sofa XL</p><p style={{ fontSize: '11px' }}>Rp 270.000</p>
                                <a href="https://api.whatsapp.com/send?phone=6281196110699&text=Halo, saya ingin pesan paket Suberes Cuci Sofa" target="_blank" style={
                                    buttonConfig.mall ?
                                        defaultHoverButton : defaultUnhoverButton
                                } onMouseOver={() => {
                                    setButtonConfig({ ['mall']: true })
                                }} onMouseOut={() => {
                                    setButtonConfig({ ['mall']: false })
                                }} className="btn btn-success">Pesan Sekarang</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-bed" style={{ backgroundColor: '#34B762', border: '1px solid #34B762', color: 'white' }}></i>
                                <p style={{ fontWeight: 'bold' }}>Suberes Cuci Karpet</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Karpet Ukuran &lt;4 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 102.500</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Karpet Ukuran 5 - 9 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 195.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Karpet Ukuran 10 - 16 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 315.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Karpet Ukuran 17 - 25 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 490.000</p>
                                <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Karpet Ukuran 26 - 36 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 720.000</p>
                                <a href="https://api.whatsapp.com/send?phone=6281196110699&text=Halo, saya ingin pesan paket Suberes Cuci Karpet" target="_blank" style={
                                    buttonConfig.vehicle ?
                                        defaultHoverButton : defaultUnhoverButton
                                } onMouseOver={() => {
                                    setButtonConfig({ ['vehicle']: true })
                                }} onMouseOut={() => {
                                    setButtonConfig({ ['vehicle']: false })
                                }} className="btn btn-success">Pesan Sekarang</a>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '50px' }} className="row" data-aos="fade-up">
                        <div className="col-lg-12 mt-5 mt-md-0">
                            <div className="count-box">
                                <i className="icofont-bed" style={{ backgroundColor: '#34B762', border: '1px solid #34B762', color: 'white' }}></i>
                                <p style={{ fontWeight: 'bold' }}>Suberes Deep Cleaning</p>
                                <div id="deep_cleaning_desc" style={{ width: '100%', textAlign: 'center', alignItems: 'center' }}>
                                    <p style={{ marginTop: '5px', fontSize: '12px', width: '50%', margin: '0 auto' }}>
                                        Pada layanan Suberes Deep Cleaning, terdapat biaya survey yang berbeda tergantung tingkatannya seperti di bawah ini sebagai biaya transport kepada mitra
                                    </p>
                                    <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kotor Rendah</p><p style={{ fontSize: '11px' }}>Rp 12.000</p>
                                    <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kotor Sedang</p><p style={{ fontSize: '11px' }}>Rp 16.000</p>
                                    <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kotor Tinggi</p><p style={{ fontSize: '11px' }}>Rp 20.000</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6 mt-5 mt-md-10">
                                        <div className="count-box">
                                            <i className="icofont-bathtub" style={{ backgroundColor: '#34B762', border: '1px solid #34B762', color: 'white' }}></i>
                                            <p style={{ fontWeight: 'bold' }}>Suberes Kamar Mandi</p>
                                            <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kamar Mandi Luas &lt;3 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 202.500</p>
                                            <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kamar Mandi Luas 3 - 6 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 280.000</p>
                                            <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kamar Mandi Luas 6 - 9 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 325.500</p>
                                            <p style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '12px' }}>Kamar Mandi Luas &gt; 9 m<sup>2</sup></p><p style={{ fontSize: '11px' }}>Rp 415.500</p>
                                            <a href="https://api.whatsapp.com/send?phone=6281196110699&text=Halo, saya ingin pesan paket Suberes Deep Cleaning" target="_blank" style={
                                                buttonConfig.mall ?
                                                    defaultHoverButton : defaultUnhoverButton
                                            } onMouseOver={() => {
                                                setButtonConfig({ ['mall']: true })
                                            }} onMouseOut={() => {
                                                setButtonConfig({ ['mall']: false })
                                            }} className="btn btn-success">Pesan Sekarang</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PricingSection