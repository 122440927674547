import React , { Component } from 'react';

export default class Footer extends Component {
    render(){
        return (
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="footer-info">
                                    <h3>Kontak Suberes</h3>
                                    <p className="pb-3">
                                        Berdiri pada tahun 2020 . Suberes merupakan jawaban dari kebutuhan akan kualitas dan ketepatan layanan jasa kebersihan profesional.
                                    </p>
                                    <p>
                                        Jl. Letjen Mt. Haryono No. Kav 10, RT.6/RW.12, Kp. Melayu, Kec. Jatinegara <br/>
                                        Kota Jakarta Timur, Daerah Khusus Ibu Kota Jakarta 1330<br/>
                                        <strong>Phone:</strong> 08118407780<br/>
                                        <strong>Email:</strong> support@suberes.com<br/>
                                    </p>
                                    {/* <div className="social-links mt-3">
                                        <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                        <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                                        <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                                        <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}