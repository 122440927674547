import React , { Component } from 'react';

class PrivacyHero extends Component {
    render(){
        return (
            <section id="hero">
                <div className="container" style={{paddingBottom : '50px'}}>
                    <div className="row">
                        <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
                            <div data-aos="zoom-out">
                                <h1>All cleaning jobs are organized with <span>Suberes Mitra</span></h1>
                                <h2>Use Suberes Mitra, you will get organized cleaning jobs and affordable price for your services</h2>
                                <div style={{ color : 'white' , fontSize : '25px' , fontWeight : 'bold' }} className="text-center text-lg-left">
                                    App will be launched soon
                                </div>
                            </div>
                        </div>
                        <div className="col-lg order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
                            <img src="assets/img/suberes_mitra_mockup.png" className="img-fluid animted" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default PrivacyHero;