import React, { Component, useEffect, useState } from 'react';

const PrivacyAbout = () => {
    const [mobile, setMobile] = useState(false)
    useEffect(() => {
        if (/Android|iPhone/i.test(navigator.userAgent)) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    })
    return (
        <div>
            {
                !mobile ?
                    <section id="layanan" className="about">
                        <div className="container">
                            <div className="row content">
                                <div className="col-xl-12">

                                    <h2>Introduction to Suberes Mitra Privacy Policy</h2>
                                    <p>Suberes respects and protects user privacy by implementing the following principles:
                                    </p>
                                    <ul>
                                        <li>Suberes Mitra will inform users about the personal information to be collected and, if necessary, seek user consent to collect, process, use, or disclose user personal information.</li>
                                        <li>Suberes Mitra will identify the purposes of collecting and further processing user personal information.</li>
                                        <li>Suberes Mitra will only collect information necessary to achieve the identified purposes of use.</li>
                                        <li>Suberes Mitra will use or disclose user personal information only for the purposes for which it was collected unless with user consent, or as required or permitted by applicable law, and Suberes will only retain user information as long as necessary to achieve those purposes.</li>
                                        <li>Suberes Mitra will make efforts to ensure that user personal information is accurate, complete, and up-to-date.</li>
                                        <li>Suberes Mitra will implement appropriate technical and organizational measures to protect user personal information.</li>
                                        <li>Suberes Mitra will make Suberes policies and implementations regarding user personal information handling as transparent as possible.</li>
                                        <li>Users will have access to their personal information and can correct their personal information as necessary according to user rights under applicable law.</li>
                                        <li>Suberes Mitra will be accountable to users. Suberes is interested in hearing users' opinions regarding Suberes' compliance with these principles, this Privacy Policy, and applicable law.</li>
                                    </ul>

                                    <h2>Scope of Privacy Policy</h2>
                                    <p>Objectives of the Privacy Policy
                                    </p>
                                    <p>
                                        This Privacy Policy explains how Suberes Mitra will process and secure user personal information, and the rights and choices users have regarding personal information. This Privacy Policy applies to: personal information users provide to Suberes Mitra when using communication applications.

                                        Suberes Mitra (Suberes Mitra website and Applications), additional services available on the Suberes Mitra Application, and other applications integrated with or working in conjunction with Suberes Mitra Applications (collectively referred to as "Applications") personal information obtained by Suberes Mitra when users use Suberes Mitra applications.

                                        ("Website." Applications and Websites collectively referred to as "Services") and personal information about users provided by other Service users.
                                    </p>

                                    <h2>Mitra information we collect
                                    </h2>
                                    <p>We collect some Personal Data directly provided by you as follows:</p>
                                    <ul>
                                        <li>Email: To send partnership application status and send OTP codes for login access and partner identification.</li>
                                        <li>Full Name: As partner identification with Suberes customers.</li>
                                        <li>Place, Date of Birth, Age, and Gender: As validation if the partner is over 18 years old and a maximum of 50 years old.</li>
                                        <li>ID Number: As identification of citizenship status.</li>
                                        <li>Phone Number (WhatsApp): Means to communicate with customers through third-party applications outside of Suberes and make phone calls to customers.</li>
                                        <li>Emergency contact name, emergency phone number, emergency contact relationship: In case something unwanted happens between the partner and the customer and the partner's phone number cannot be contacted, Suberes will conduct further investigation through the emergency contact number.</li>
                                        <li>Domicile address: As verification of the partner's residence if the partner does something unwanted to Suberes customers Address on ID card, RT/RW, Village, District, City, Province, Postal Code: Identification of matching addresses written with the address on the ID card.</li>
                                        <li>Face photo: Customer identification of the partner.</li>
                                        <li>ID card photo: Prove the citizenship of prospective partners.</li>
                                        <li>Family card photo: To verify ID card data with Family card data.</li>
                                        <li>Registering with own cleaning tools: To ensure that the partner has the tools to work.</li>
                                    </ul>

                                    <h2>Information protection</h2>
                                    <p>We store user information on secure servers and systems. We have policies and procedures to protect Mitra information from unauthorized access, use, or disclosure.</p>

                                    <h2>Changes to the Privacy Policy</h2>
                                    <p>We may update this privacy policy from time to time. If we make changes to the Suberes Mitra application system, we will notify users through the application or through posts on our website.</p>

                                    <h2>User data security</h2>
                                    <p>The confidentiality of your data and Personal Data is our top priority. We make our best efforts and procedures to protect and ensure Personal Data. However, we cannot guarantee 100% that our system cannot be penetrated by viruses, malware, and disruptions from third parties. Please do not disclose and share your Personal Data, including your account passwords, and keep your device secure.</p>
                                    
                                    <h2>Requirements and consent</h2>
                                    <p>By using our Website/Application/Services, you acknowledge that you have read and understood this Privacy Policy and the Terms of Use and agree and consent to allow us to use, apply, process, and transfer your Personal Data as stated in this Privacy Policy.</p>

                                    <p>
                                    You also acknowledge that you have the right to disclose all the information provided to us and give us the authority to use and share your Personal Data to support and implement our products/services.
                                    </p>

                                    <h2>Contact Us</h2>
                                    <p>
                                    If you have any questions or concerns about this Privacy Policy or the use of Personal Data, please contact us at:
                                    </p>
                                    <p>
                                    PT Karya Kita Sukses<br/> MTH Square GFA4/A Jl. Lejten M.T. Haryono Kav lO.Cawang,
                                    East Jakarta 13330 <br/>Indonesia Phone: +62 2158905002
                                    <br/>By using our Website/Application/Services, you are deemed to agree to this Privacy Policy including but not limited to all its changes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section id="layanan" className="about">
                        <div className="container">
                            <div className="section-title" data-aos="fade-up">
                                <p style={{ fontSize: '25px', textTransform: 'none' }}>Layanan Kami</p>
                            </div>
                            <div className="row content">
                                {/* <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                        <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                    </div> */}
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/GeneralCleaning.jpg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                                    <h3>General Cleaning</h3>
                                    <p>Layanan yang dilakukan pada waktu-waktu tertentu saja.
                                        Layanan ini sangat cocok dipakai untuk membersihkan rumah setelah pindahan dengan menerapkan sistem detail cleaning Untuk rumah
                                        pribadi / ruangan kantor yang akan digunakan.
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                            <div className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/HomeCleaning.jpg" class="img-fluid" alt="" />
                                </div>
                                <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Home Cleaning</h3>
                                    <p>
                                        Layanan kebersihan yang paling menjadi favorit. Yang mana pembersihan dilakukan secara menyeluruh. Meliputi
                                        kegiatan kebersihan rumah sehari-sehari atau suatu ruangan sesuai dengan jenis kebutuhan. Tidak hanya rumah namun bisa juga perkantoran atau pertokoan
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginTop: '20px' }} className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px', width: '360px', height: '300px', objectFit: 'cover' }} src="assets/img/FoggingCleaning.jpeg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Fogging</h3>
                                    <p>Layanan kebersihan khusus untuk menghambat atau membunuh bakteri, virus dan jamur. Yang menggunak cairan pembersihan yang sudah teruji kandungan nya dan aman untuk manusia .
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                            <div className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px', width: '360px', height: '300px', objectFit: 'cover' }} src="assets/img/DeepCleaning.png" class="img-fluid" alt="" />
                                </div>
                                <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Deep Cleaning</h3>
                                    <p>
                                        Deep Cleaning adalah melakukan pembersihan rumah secara lengkap pada seluruh bagian rumah. Deep cleaning hanya dilakukan oleh petugas Jasa Cleaning Service dengan peralatan khusus yang dapat melakukannya.
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginTop: '15px' }} className="row content">
                                <div className="col-md-4 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                    <img style={{ borderRadius: '10px' }} src="assets/img/VacuumTungau.jpg" class="img-fluid" alt="" />
                                </div>
                                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-right">
                                    <h3>Vacuum Tungau</h3>
                                    <p>Jasa sedot atau vacuum tungau dan debu dengan menggunakan HydroAllergenic®️ System berteknologi separator, yang memiliki daya hisap lebih dari 20.000 rpm dan mampu memisahkan udara, partikel debu dan tungau.
                                    </p>
                                    {/* <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a href="">Lorem ipsum</a></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </div>
    )
}

export default PrivacyAbout